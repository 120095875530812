import React from 'react';
import { Link } from 'react-router-dom';
import './Logo.css';

interface LogoProps {
  variant: 'pause' | 'pauseListen' | 'full';
}

const Logo: React.FC<LogoProps> = ({ variant }) => {
  return (
    <Link to="/" className="logo-text">
      <span className="logo-part-one">Pause</span>
      <span className="logo-period">.</span>
      {variant === 'pause' && (
        <><span className="logo-part-one"> Co</span><span className="logo-period">.</span></>
      )}
      {(variant === 'pauseListen' || variant === 'full') && (
        <span className="logo-part-two">
          Listen<span className="logo-period">.</span>
        </span>
      )}
      {variant === 'full' && (
        <span className="logo-part-three"> Calibrate. Act.</span>
      )}
    </Link>
  );
};

export default Logo;

import React from 'react';
import './Feature.css';

// Import images
import heroImage from '../../assets/images/hero-image.png';
import conversation from '../../assets/images/conversation.png';
import engage from '../../assets/images/engage.png';
import iceBreaker from '../../assets/images/ice-breaker.png';
import stayOnTrack from '../../assets/images/stay-on-track.png';
import transcribe from '../../assets/images/transcribe.png';
import wiki from '../../assets/images/wiki.png';

interface ImageMap {
    [key: string]: string;
}

const imageMap: ImageMap = {
    'hero-image.png': heroImage,
    'conversation.png': conversation,
    'engage.png': engage,
    'ice-breaker.png': iceBreaker,
    'stay-on-track.png': stayOnTrack,
    'transcribe.png': transcribe,
    'wiki.png': wiki
};

interface FeatureProps {
    title: string;
    points: string[];
    imageName?: string;
    imageSize?: number;
}

const Feature: React.FC<FeatureProps> = ({ title, points, imageName, imageSize = 1 }) => {
    const imageStyle = {
        transform: `scale(${imageSize})`,
    };

    return (
        <div className="feature">
            <div className="feature-content">
                {/* Separate container for the title */}
                <div className="feature-title">
                    <h1>{title}</h1>
                </div>

                {/* New container for image and text side by side */}
                <div className="feature-row">
                    <div className="feature-visual">
                        {imageName && imageMap[imageName] ? (
                            <img 
                                src={imageMap[imageName]} 
                                alt={title} 
                                className="feature-image" 
                                style={imageStyle}
                            />
                        ) : (
                            <div className="feature-image-placeholder"></div>
                        )}
                    </div>
                    <div className="feature-text">
                        <ul>
                            {points.map((point, index) => (
                                <li className="primary-text" key={index}>{point}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Feature;

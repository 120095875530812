import React from 'react';
import './WhyListen.css';

const WhyListen: React.FC = () => {
    return (
        <div className="fade-in-top">
            <div className="whylisten-container container-max-width">
                <h1 className="title">Why Listen.</h1>
                <div className="content-section">
                    <p className="subtitle whylisten-primary-text">We all know the frustration: unproductive meetings, disjointed teams, and elusive goals, often stemming from poor communication and misalignment.</p>
                    <p className="subtitle whylisten-primary-text">Effective communication is not just crucial; it&apos;s transformative, aligning everyone towards common objectives.</p>
                    <p className="subtitle whylisten-primary-text">At the heart of effective communication is <b>quality conversations</b>. They foster the sharing of feedback and perspectives, encourage collaborative exploration, and shed light on the path forward.</p>
                    <p className="subtitle whylisten-primary-text"><span className="product-name">Listen</span> doesn&apos;t just elevate your team&apos;s conversations; it enhances decisions and overall impact, fostering unity and helping your team act as one.</p>
                </div>
                <section className="cta-section">
                    <button className="button-large">Start Your Learning Journey</button>
                </section>
            </div>
        </div>
    );
};

export default WhyListen;

import React from 'react';
import { Helmet } from 'react-helmet';
import './Register.css';

const Confirmation: React.FC = () => {
    return (
        <div className="fade-in-top">
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <title>Confirmation Page</title>
            </Helmet>
            <div className="register-container">
                <h1 className="title register-title">Welcome aboard!</h1>
                <p className="subtitle register-subtitle">Your spot is reserved. You will hear from us soon with more information.</p>
            </div>
        </div>
    );
};

export default Confirmation;

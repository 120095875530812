import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import Logo from '../Logo/Logo';

const Header: React.FC = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        console.log('Toggling menu');
        setMenuOpen(!isMenuOpen);
    };
    

    return (
        <header className="header-container">
            <div className="header-content">
            <div className="left-side">
                <div className="header-logo">
                    <Logo variant='pause' />
                </div>
                <nav className={`header-nav ${isMenuOpen ? 'nav-open' : ''}`}>
                    <ul className="nav-list">
                        <li className="nav-item">
                            <Link to="/whylisten" className="nav-link">Why Listen</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/vision" className="nav-link">Vision</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/pricing" className="nav-link">Pricing</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/register" className="nav-link">Early Access</Link>
                        </li>
                    </ul>
                </nav>
            </div>
                <div className="right-side">
                    <button onClick={toggleMenu} className="hamburger" type="button">
                        ☰
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;

import React from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './styles/global.css';

const App: React.FC = ({ children }) => {
  return (
    <div className="App">
      <Header />
      <div className="content">
        {children}
      </div>
      <Footer />
    </div>
  );
}

export default App;

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home/Home';
import WhyListen from '../pages/WhyListen/WhyListen';
import Vision from '../pages/Vision/Vision';
import Pricing from '../pages/Pricing/Pricing';
import Register from '../pages/Register/Register';
import Confirmation from '../pages/Register/Confirmation';
import Privacy from '../pages/Privacy/Privacy';
import Legal from '../pages/Legal/Legal';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-QPB2RMFZ08');
//import NotFound from '../pages/NotFound/NotFound';
import RouteChangeTracker from '../components/RouteChangeTracker/RouteChangeTracker';

const Routes = () => {
    return (
        <Router>
            <RouteChangeTracker />
            <App>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/whylisten" component={WhyListen} />
                    <Route path="/vision" component={Vision} />
                    <Route path="/pricing" component={Pricing} />
                    <Route path="/register" component={Register} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/legal" component={Legal} />
                    <Route path="/confirmation" component={Confirmation} />
                    {/* <Route component={NotFound} /> */}
                </Switch>
            </App>
        </Router>
    );
}

export default Routes;

import React from 'react';
import './Vision.css';

const Vision: React.FC = () => {
    return (
        <div className="fade-in-top">
            <div className="vision-container container-max-width">
                <h1 className="title">Our Vision.</h1>
                <div className="content-section">
                    <p className="subtitle vision-primary-text">Meetings have long been the scapegoat for workplace inefficiency—dismissed as dull, ineffective, and unproductive. In a zeitgeist that campaigns for &apos;zero meetings&apos; and other radical approaches, we offer a provocative counter-view: these gatherings harbour untapped group intelligence.</p>
                    <p className="subtitle vision-primary-text">We see a world where humans working together navigate complexity and challenge with ease. Where teams respond as if intelligent organisms, rather than cogs in an overarching plan.</p>
                    <p className="subtitle vision-primary-text">But achieving this requires far more than superficial agreements or cursory nods. Quality meetings harness diverse wisdom and viewpoints, weaving them into a coherent shared narrative that drives informed action and success.</p>
                    <p className="subtitle vision-primary-text">The key lies in communication. It transcends mere information exchange and action item reviews; it&apos;s about listening to each other and intelligently responding to the signal shared.  Effective communication enables teams to feel, think, and act in harmony, harnessing the unique perspectives each individual contributes.</p>
                    <p className="subtitle vision-primary-text">Ultimately our vision transcends communication and mere meetings; it&apos;s about teams acting in alignment, navigating complexity with wisdom, and actualising their collective visions.</p>
                </div>
            </div>
        </div>
    );
};

export default Vision;

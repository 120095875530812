import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';

const Footer: React.FC = () => {
    return (
        <div className="footer-container">    
            <div className="footer-links container-max-width">
                <div className="link-group">
                    <Link className="primary-link" to="/whylisten">Why Listen</Link>
                    <Link className='secondary-text' to="/vision">Vision</Link>
                    <Link className='secondary-text' to="/pricing">Pricing</Link>
                </div>
                <div className="link-group">
                    <Link className="primary-link" to="/register">Early Access</Link>
                    <Link className='secondary-text' to="/legal">Legal</Link>
                    <Link className='secondary-text' to="/privacy">Privacy</Link>
                </div>
            </div>

            <div className="footer-top container-max-width">
                <div className="footer-motto">
                    <div className="logo-full">
                      <Logo variant='full' />
                    </div>
                    <div className="logo-pauseListen">
                      <Logo variant='pauseListen' />
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="copyright-full">
                    <h4>© 2023 Pause. All rights reserved.</h4>
                    </div>
                    <div className="copyright-small">
                    <h4>© 2023 Pause.</h4>
                    </div>                    
                    
                </div>
            </div>
        </div>
    );
}

export default Footer;

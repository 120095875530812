// src/pages/Register/Register.tsx
import React from 'react';
import './Register.css';

const Register: React.FC = () => {
    return (
        
        <div className="fade-in-top">
            <div className="register-container">
                <h1 className="title register-title">Embark on Your Learning Adventure</h1>
                <p className="primary-text register-subtitle">Secure Your Spot on the Early Access Waitlist.</p>
                <iframe className='register-form'
                    src="https://fa6a52c8.sibforms.com/serve/MUIFABXx2x42DRa82yS5AOmfOKiVk_IE_OOzw3j952mGmhVLVqBjO6btCxmV470mh0RNCwekwvwy6VL47gHY7xGD-Zzu9RU1_PcRnA7cRx2F57zdXXjg66zq4UExqLlhRyP8XvaZWOFvyv7HersuESym1-QirMhcaRtQiT_qBuY0br5GhZOgJiqQxv-Z-1fy8L0SteY9wpVu6_X-"
                    frameBorder="0" 
                    scrolling="no" 
                    allowFullScreen 
                    style={{display: 'block', margin: 'auto', maxWidth: '100%'}}
                />
            </div>
        </div>
    );
};

export default Register;
import React from 'react';
import '../Privacy/Privacy.css'; // Reusing the Privacy.css for styling

const Terms: React.FC = () => {
    return (
        <div className="fade-in-top">
            <div className="privacy-container container-max-width">
                <h1 className="title">Pre Release Terms and Conditions for Pause</h1>

                <div className="content-section">
                    {/* Acceptance of Terms */}
                    <h2>1. Acceptance of Terms</h2>
                    <p className="privacy-text">
                        Effective Date: 14th November 2023<br /><br />
                        By accessing and using the website of Pause (&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;), you agree to be bound by these Terms and Conditions (&quot;Terms&quot;). If you do not agree with any part of these Terms, please do not use our website.
                    </p>

                    {/* Use of the Website */}
                    <h2>2. Use of the Website</h2>
                    <p className="privacy-text">
                        Our website is intended to provide information about our upcoming products and services. The website is for your personal and non-commercial use only.
                    </p>

                    {/* Intellectual Property Rights */}
                    <h2>3. Intellectual Property Rights</h2>
                    <p className="privacy-text">
                        The content on our website, including text, graphics, logos, and images, is our property or the property of our content suppliers and is protected by intellectual property laws. You may not use any content from our website without our prior written permission.
                    </p>

                    {/* User Conduct */}
                    <h2>4. User Conduct</h2>
                    <p className="privacy-text">
                        You agree to use our website only for lawful purposes. You are prohibited from using our website to transmit or share any illegal, harmful, or offensive material.
                    </p>

                    {/* Third-Party Links */}
                    <h2>5. Third-Party Links</h2>
                    <p className="privacy-text">
                        Our website may contain links to third-party websites. We have no control over, and assume no responsibility for, the content or practices of any third-party websites.
                    </p>

                    {/* Disclaimer of Warranties */}
                    <h2>6. Disclaimer of Warranties</h2>
                    <p className="privacy-text">
                        Our website is provided on an &quot;as is&quot; and &quot;as available&quot; basis. We do not make any warranties, express or implied, regarding the operation or content of our website.
                    </p>

                    {/* Limitation of Liability */}
                    <h2>7. Limitation of Liability</h2>
                    <p className="privacy-text">
                        To the fullest extent permitted by law, Pause shall not be liable for any indirect, incidental, consequential, or punitive damages resulting from your access to or use of our website.
                    </p>

                    {/* Changes to Terms */}
                    <h2>8. Changes to Terms</h2>
                    <p className="privacy-text">
                        We reserve the right to modify these Terms at any time. We will post the revised Terms on our website and update the &quot;Effective Date&quot; at the top of these Terms. Your continued use of the website after such changes constitutes your acceptance of the new Terms.
                    </p>

                    {/* Governing Law */}
                    <h2>9. Governing Law</h2>
                    <p className="privacy-text">
                        These Terms are governed by the laws of the jurisdiction in which our company is established, without regard to its conflict of law principles.
                    </p>

                    {/* Contact Us */}
                    <h2>10. Contact Us</h2>
                    <p className="privacy-text">
                        If you have any questions about these Terms, please contact us at hello@letspause.co.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Terms;

// src/pages/Pricing/Pricing.tsx
import React from 'react';
import './Pricing.css';
import { useHistory } from 'react-router-dom';

const Pricing: React.FC = () => {
    const history = useHistory();
    // Function to navigate to Register page
    const goToRegister = () => {
        history.push('/register');
    };

    return (
        <div className="fade-in-top">
            <div className="pricing-container container-max-width">
                <div className="pricing-header">
                    <h1 className="title"><span className='product-name'>Listen</span> Plans</h1>
                </div>

                <div className="pricing-cards">
                    
                    {/* Early Access Card */}
                    <div className="pricing-card">
                        <h2 className="pricing-title">Limited Early Access</h2>
                        <h2 className="pricing-amount">Free</h2>
                        <ul className="pricing-features">
                            <li>Insightful meeting analytics</li>
                            <li>Communication pattern monitoring</li>
                            <li>Real time smart meeting guidance</li>
                            <li>Accurate transcription</li>
                            <li>Limited monthly hours</li>
                        </ul>
                        <button className="button-primary pricing-button" onClick={goToRegister}>Sign up for free</button>
                    </div>

                    {/* Standard Card */}
                    <div className="pricing-card">
                        <h2 className="pricing-title">Standard Plan</h2>
                        <p className="pricing-amount"></p>
                        <ul className="pricing-features">
                        <li><b>Details to be announced</b></li>
                        </ul>
                        <button className="button-primary pricing-button">Choose Standard</button>
                    </div>

                    {/* Pro Card */}
                    <div className="pricing-card">
                        <h2 className="pricing-title">Pro Plan</h2>
                        <p className="pricing-amount"></p>
                        <ul className="pricing-features">
                            <li><b>Details to be announced</b></li>
                        </ul>
                        <button className="button-primary pricing-button">Choose Pro</button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Pricing;

import React from 'react';
import './Privacy.css'; // Ensure the CSS file is in place

const Privacy: React.FC = () => {
    return (
        <div className="fade-in-top">
            <div className="privacy-container container-max-width">
                <h1 className="title">Pre Release Privacy Policy of Pause</h1>

                <div className="content-section">
                    {/* Introduction */}
                    <h2>Introduction</h2>
                    <p className="privacy-text">
                        Effective Date: 14th November 2023<br /><br />
                        Pause (&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;) will provide applications that process voice recordings from meetings to offer analytics and real-time feedback on communication patterns. This Privacy Policy outlines our practices regarding the collection, use, and protection of personal information we receive during this pre-release phase.
                    </p>

                    {/* Information Collection */}
                    <h2>Information Collection</h2>
                    <p className="privacy-text">
                        We collect personal information such as email addresses through our website form. This collection is facilitated by our third-party email list provider.
                    </p>

                    {/* Use of Information */}
                    <h2>Use of Information</h2>
                    <p className="privacy-text">
                        The information collected is primarily used to communicate with users about the progress, updates, and release of our application. We do not engage in any specific data processing or analysis methods beyond these purposes.
                    </p>

                    {/* Data Sharing and Disclosure */}
                    <h2>Data Sharing and Disclosure</h2>
                    <p className="privacy-text">
                        Your personal information is stored and managed by a third-party email list provider, under their privacy and security protocols. Additionally, we use Google Analytics for website analytics purposes, which may collect data regarding your interaction with our website.
                    </p>

                    {/* Data Security */}
                    <h2>Data Security</h2>
                    <p className="privacy-text">
                        Pause prioritizes the security of your data. However, as we rely on third-party services for data storage and management, we recommend reviewing their privacy policies to understand their security measures.
                    </p>

                    {/* User Rights and Choices */}
                    <h2>User Rights and Choices</h2>
                    <p className="privacy-text">
                        Users can unsubscribe from our communications at any time, effectively removing their email address from our mailing list.
                    </p>

                    {/* Cookies and Tracking Technologies */}
                    <h2>Cookies and Tracking Technologies</h2>
                    <p className="privacy-text">
                        Our website uses Google Analytics, which employs cookies and similar tracking technologies to track user interactions. This data helps us improve our website and services.
                    </p>

                    {/* Policy Updates */}
                    <h2>Policy Updates</h2>
                    <p className="privacy-text">
                        This Privacy Policy may be updated periodically, especially as we approach the product launch. We encourage users to review this policy regularly for any changes.
                    </p>

                    {/* Contact Information */}
                    <h2>Contact Information</h2>
                    <p className="privacy-text">
                        If you have any questions or concerns about this Privacy Policy, please contact us at hello@letspause.co.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Privacy;

// src/pages/Home/Home.tsx

import React from 'react';
import { useHistory } from 'react-router-dom';
import './Home.css';
import heroImage from '../../assets/images/hero-image.png';
import backgroundImage from '../../assets/images/home-background.jpg';
import Feature from '../../components/Feature/Feature';

const Home: React.FC = () => {
    const history = useHistory();

    // Function to navigate to Register page
    const goToRegister = () => {
        history.push('/register');
    };
    return (
        <div className="home-container">
            <div className="background-container fade-in-top" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="top-section container-max-width">
                    <div className="top-text">
                        <h1 className="title">Craft Incredible Teams with <span className='product-name'>Listen</span>.</h1>
                        <p className="primary-text">
                            <span className='product-name'>Listen</span> seamlessly integrates with video conferencing platforms, providing real-time analytics and in-meeting guidance to improve your leadership and facilitation skills. With <span className='product-name'>Listen</span>, you&apos;ll be equipped to cultivate an environment of trust, diversity, and alignment, empowering you to harness collective intelligence and shape impactful teams that act as one.
                        </p>
                        <div className="cta-buttons">
                            <button className="button-primary" onClick={goToRegister}>Start Your Learning Journey</button>
                        </div>
                        <p className="secondary-text">
                            Chrome Extension works with major meeting platforms, Google Meet, Zoom, and Microsoft Teams.
                        </p>
                    </div>
                    <img src={heroImage} alt="Hero for Listen" className="image-placeholder" />
                </div>
            </div>

           {/* Introductory Section */}
           <div className="introductory-section container-max-width">
                <h2>Discover How <b>Listen</b> Supports You In Learning</h2>
            </div>            

            <Feature
                title="Build Psychological Safety"
                points={[
                    "Recognize and address signs of low psychological safety, the cornerstone of top-performing teams as identified by Google's Project Aristotle Study.",
                    "Adopt strategies that build trust and open communication.",
                    "Use proven methods to boost your team's psychological wellbeing, fostering top performance and creativity."
                ]}
                imageName='engage.png'
                imageSize={1.35}
            />

            <Feature
                title="Facilitate with Confidence"
                points={[
                    "Gain the right language to effectively guide the conversation.",
                    "Identify and resolve unproductive meeting dynamics.",
                    "Create balanced participation and engagement in discussions."
                ]}
                imageName='conversation.png'
            />

            <Feature
                title="Learn Using Educational Resources and Activities"
                points={[
                    "Enhance teamwork and discussion quality with guided activities.",
                    "Access a diverse knowledge library written by experts on teams, communication, and leadership.",
                    "Explore activities ranging from icebreakers to strategic tools."
                ]}
                imageName='ice-breaker.png'
                imageSize={1.1}
            />

            <Feature
                title="Align Decision-Making"
                points={[
                    "Learn to ask clear, impactful questions.",
                    "Clarify and resolve ambiguities swiftly during discussions.",
                    "Master decision-making techniques for aligned team outcomes."
                ]}
                imageName='wiki.png'
                imageSize={1.1}
            />

            <Feature
                title="As a Co-Facilitator"
                points={[
                    "Ensure meetings stay within scheduled timeframes.",
                    "Implement advanced time management strategies for meetings.",
                    "Make your meetings more productive and focused."
                ]}
                imageName='stay-on-track.png'
            />            

            <Feature
                title="Provide Real-Time Transcription"
                points={[
                    "Instantly transcribe Chrome-based video meetings.",
                    "Integrate seamlessly with Google Drive for easy transcription access."
                ]}
                imageName='transcribe.png'
            />

            <section className="cta-section">
                <button className="button-primary" onClick={goToRegister}>Start Your Learning Journey</button>
            </section>

            <section className="testimonials-section">
            </section>
        </div>
    );
};

export default Home;
